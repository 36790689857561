import React, { useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useMediaQuery } from 'react-responsive';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import Alert from '../../../components/Alert/Alert';
import PaginationNav from '../../../components/PaginationNav/PaginationNav';
import SortControls from '../../../components/SortControls/SortControls';
import PillSortButton from '../../../components/PillInput/PillSortButton';

import { charges } from '../../../utils/charges';
import { convictions } from '../../../utils/convictions';
import { getPaginatedResults, orderByDate } from '../../../utils/sorting';
import { FaExternalLinkAlt } from 'react-icons/fa';

const ChargesPage = () => {
	const intl = useIntl();
	const isScreenSm = useMediaQuery({ maxWidth: 1200 });
	const [currentPage, setPage] = useState(1);
	const [orderBy, setOrderBy] = useState('defendant');
	const [direction, setDirection] = useState('asc');

	// const consolidatedList = sortAsc([...charges, ...convictions], 'defendant')
	const consolidatedList = orderByDate(
		[...charges, ...convictions].map((entry) => {
			entry.sortDate = entry.convictionDate ? entry.convictionDate : entry.chargeDate;
			return entry;
		}),
		'sortDate'
	);

	// const setListOrder = (filterId) => {
	//   if (filterId === setOrderBy && setDirection === "asc")
	//     orderSearch({ filter: filterId, order: "desc" });
	//   else if (filterId === searchOrderDirection)
	//     orderSearch({ filter: filterId, order: "asc" });
	//   else orderSearch({ filter: filterId, order: "asc" });
	// };

	return (
		<Layout
			header={
				<span className='bold'>
					{/* <FormattedMessage id='nav.charges' /> */}
					Court Charges and/or Convictions
				</span>
			}
			breadcrumb={{
				text: <FormattedMessage id='nav.regActivites' />,
				path: '/licensing-compliance/regulatory-activites-enforcement/'
			}}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.regActivitiesDescription'
				})}
				lang={intl.locale}
				// title={intl.formatMessage({ id: 'nav.charges' })}
				title='Court Charges and/or Convictions'
			/>
			<Container>
				<Row className='pageRow'>
					<Col>
						{intl.locale === 'fr' && (
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						)}
						{/* <div
              style={{ marginBottom: `0` }}
              className='flexContainer mobileCol justifyBetween alignEnd'
            >
              <SortControls>
                <PillSortButton
                  id='defendant'
                  label='Defendant'
                  active={orderBy === 'defendant'}
                  order={direction}
                  clickEvt={() => setOrderBy('defendant')}
                />

                <PillSortButton
                  id='chargeDate'
                  label='Charge Date'
                  active={orderBy === 'chargeDate'}
                  order={direction}
                  clickEvt={() => setOrderBy('chargeDate')}
                />

                <PillSortButton
                  id='convictionDate'
                  label='Conviction Date'
                  active={orderBy === 'convictionDate'}
                  order={direction}
                  clickEvt={() => setOrderBy('convictionDate')}
                />
              </SortControls>
            </div> */}

						{isScreenSm ? (
							<>
								{getPaginatedResults(consolidatedList, currentPage).map((conviction, i) => (
									<div className='tile' key={i}>
										<p>
											<a href={conviction.link} className='linkHeader'>
												<FaExternalLinkAlt />
												{conviction.defendant}
											</a>
											<br />
											<b>Defendant Location:</b> {conviction.dLocation}
											<br />
											<b>Offence Location:</b> {conviction.oLocation}
											<br />
											<b>Charge Date:</b> {conviction.chargeDate}
											<br />
											<b>Charge:</b> {conviction.charge}
											<br />
											<b>Conviction Date:</b>{' '}
											{conviction.convictionDate ? conviction.convictionDate : '---'}
											<br />
											<b>Result:</b> {conviction.result ? conviction.result : '---'}
											<br />
											<b>Fine/Surcharge Amount:</b>{' '}
											{conviction.fineAmount ? conviction.fineAmount : '---'}
											<br />
											<b>Under Appeal:</b> {!conviction.underAppeal ? '---' : 'Yes'}
										</p>
									</div>
								))}
								{consolidatedList.length > 10 && (
									<PaginationNav
										currentPage={currentPage}
										clickEvt={setPage}
										resultsLength={consolidatedList.length}
									/>
								)}
							</>
						) : (
							<div>
								<div className='overflowContainer'>
									<table className='noWrap'>
										<thead>
											<tr>
												<th>Defendant</th>
												<th>Defendant Location</th>
												<th>Offence Location</th>
												<th>Charge Date</th>
												<th>Charge</th>
												<th>Conviction Date</th>
												<th>Result</th>
												<th>Fine/Surcharge Amount</th>
												<th>Under Appeal</th>
											</tr>
										</thead>
										<tbody>
											{getPaginatedResults(consolidatedList, currentPage).map((conviction, i) => (
												<tr key={i}>
													<td>
														<a href={conviction.link} className='linkHeader'>
															<FaExternalLinkAlt />
															{conviction.defendant}
														</a>
													</td>
													<td>{conviction.dLocation}</td>
													<td>{conviction.oLocation}</td>
													<td>{conviction.chargeDate}</td>
													<td>{conviction.charge}</td>
													<td>
														{conviction.convictionDate ? conviction.convictionDate : '---'}
													</td>
													<td>{conviction.result ? conviction.result : '---'}</td>
													<td>{conviction.fineAmount ? conviction.fineAmount : '---'}</td>
													<td>{!conviction.underAppeal ? '---' : 'Yes'}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								{consolidatedList.length > 10 && (
									<PaginationNav
										currentPage={currentPage}
										clickEvt={setPage}
										resultsLength={consolidatedList.length}
									/>
								)}
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default ChargesPage;
